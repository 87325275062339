import React, { useEffect, useState, useContext } from "react";
import { Button } from "antd";
import Baner from "./../../../assets/suc-khoe-vang/baner.png";
import Intro2 from "./../../../assets/suc-khoe-vang/intro2.png";
import Intro3 from "./../../../assets/suc-khoe-vang/intro3.png";
import Intro4 from "./../../../assets/suc-khoe-vang/intro4.png";
import Zalo from "./../../../assets/suc-khoe-vang/zalo.png";
import Phone from "./../../../assets/suc-khoe-vang/phone.png";
import Email from "./../../../assets/suc-khoe-vang/email.png";
import Iconi from "./../../../assets/suc-khoe-vang/iconi.png";
import helper_center from "./../../../extra/helper";
import { AuthContext } from "./../index";
import History from "./../../../routes/history";

const Introduce = () => {
  const { OpenLink, goStep, setVAT, setDataIsu, setListIsu } =
    useContext(AuthContext);
  const platform = helper_center.getParamUrlByKey("platform");

  useEffect(() => {
    window.scrollTo(0, 0);
    const dataIsuskv = JSON.parse(localStorage.getItem("dataIsuskv"));
    const listInsuredSKV = JSON.parse(localStorage.getItem("listInsuredSKV"));
    const vat = JSON.parse(localStorage.getItem("vatskv"));
    if (dataIsuskv) {
      setDataIsu(dataIsuskv);
    }
    if (listInsuredSKV) {
      setListIsu(listInsuredSKV);
    }
    if (vat) {
      setVAT(vat);
    }
  }, []);

  const css = {
    link: {
      marginTop: 10,
      padding: 5,
      display: "flex",
      alignItems: " center",
      background:
        "linear-gradient(270deg, rgba(144, 238, 159, 0.2) -69.53%, rgba(154, 241, 169, 0.01) 100%)",
    },
    btn: {
      color: "#009B4B",
      background: "#FFF",
      fontWeight: "bold",
      width: "48%",
      height: "44px",
      borderRadius: 8,
      border: "1px solid #009B4B",
    },
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <img style={{ width: "100%" }} src={Baner} alt="Global Care" />
      <img
        style={{ width: "100%", padding: "10px 10px 0px 10px" }}
        src={Intro2}
        alt="Global Care"
      />
      <img
        style={{ width: "100%", padding: "10px 0px 0px 0px" }}
        src={Intro3}
        alt="Global Care"
      />
      <img
        style={{ width: "100%", padding: "10px 0px 0px 0px" }}
        src={Intro4}
        alt="Global Care"
      />
      <div style={{ margin: 10, fontSize: 15 }}>
        <div
          onClick={() => {
            OpenLink(
              "https://drive.google.com/file/d/1Z4I1dVRtSkYTjcJjZnVV8Y09RufugZDh/preview"
            );
          }}
          style={css.link}
        >
          <img src={Iconi} style={{ width: 25, marginRight: 7 }} />
          <span>Chi tiết điều kiện và điều khoản bảo hiểm.</span>
        </div>
        <div
          onClick={() => {
            OpenLink(
              "http://www.baominh.com.vn/uploads/source/DS%20BVLP%20CAP%20NHAT%2025-04-2023.pdf"
            );
          }}
          style={css.link}
        >
          <img src={Iconi} style={{ width: 25, marginRight: 7 }} />
          <span>Danh sách bệnh viện liên kết.</span>
        </div>
      </div>
      <div style={{ textAlign: "center", fontWeight: 600, marginTop: 10 }}>
        Liên hệ CSKH để được <span style={{ color: "#009B4B" }}>hỗ trợ</span> và{" "}
        <span style={{ color: "#009B4B" }}>hướng dẫn.</span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          margin: "10px 10px 86px 10px",
        }}
      >
        <img
          onClick={() => {
            if (platform === "app") {
              window.ReactNativeWebView.postMessage("/gotozalo");
            }else{
              window.open("https://chat.zalo.me/?c=648487566003209799", "_blank");
            }
          }}
          style={{ width: 45 }}
          src={Zalo}
          alt="Global Care"
        />
        <a href="tel:1900292987">
          <img style={{ width: 45 }} src={Phone} alt="Global Care" />
        </a>
        <a
          onClick={() => window.open("mailto:cskh@globalcare.com.vn", "_blank")}
        >
          <img style={{ width: 45 }} src={Email} alt="Global Care" />
        </a>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "fixed",
          width: "100%",
          maxWidth: 800,
          bottom: 0,
          height: 76,
          background: "#fff",
          borderRadius: "16px 16px 0px 0px",
          alignItems: "center",
          boxShadow: "0px -4px 8px 0px #0000001A",
          padding: "0px 10px",
        }}
      >
        <Button onClick={() => goStep(5)} style={css.btn} type="#009B4B">
          Mua gia hạn
        </Button>
        <Button
          onClick={() => goStep(2)}
          style={{ ...css.btn, color: "#fff", background: "#009B4B" }}
          type="#009B4B"
        >
          Mua mới
        </Button>
      </div>
    </div>
  );
};

export default Introduce;
